import type { RDMDOpts as Opts } from '@readme/iso';

interface Props {
  rdmd?: {
    opts: Opts;
  };
}

interface State {
  rdmd?: {
    opts: Opts;
  };
}

type Extra = Opts;

const useRdmdOpts = (props: Props, state: State, extra: Extra): Opts => {
  // We pull the rdmd opts from props on initial load, and then from state on
  // each navigation.
  return {
    ...extra,
    ...props.rdmd?.opts,
    ...state.rdmd?.opts,
  };
};

export default useRdmdOpts;
