import PropTypes from 'prop-types';
import React, { useContext, useCallback, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { ProjectContext, VersionContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useRdmdOpts from '@core/hooks/useRdmdOpts/legacy';
import useRoute from '@core/hooks/useRoute';
import { useProjectStore } from '@core/store';

import DocFooter from '@ui/DocFooter';
import Sidebar from '@ui/Sidebar';

import DocBody from './Body';
import Header from './Header';
import SafeModeToggle from './SafeModeToggle';
import './style.scss';

const Doc = ({ suggestedEditsPreview, ...props }) => {
  const enableSuggestedEdits = useProjectStore(s => s.data.suggested_edits === 'enabled');
  const { isClient } = useEnvInfo();
  const { project } = useContext(ProjectContext);
  const { version } = useContext(VersionContext);

  const sidebars = props.sidebars;
  const [safeMode, setSafeMode] = useState(!!suggestedEditsPreview);

  const setupMeta = useCallback(
    meta => {
      // We have to add the sidebar to the metadata so we can display the correct subnav dropdown on mobile
      if (sidebars?.docs) meta.sidebar = sidebars?.docs;
    },
    [sidebars?.docs],
  );
  const { state, loading, redirected, redirectedPath } = useRoute(props, setupMeta);
  const location = useLocation();
  const { doc = {} } = state;

  // TODO refactor this?
  const guideSidebar = {
    categories: sidebars?.docs || [],
    activeDoc: props.activeDoc || '',
  };

  const rdmdOpts = useRdmdOpts(props, state, { safeMode });
  const dehydrated = { ...props.rdmd?.dehydrated, ...state?.rdmd?.dehydrated };

  // If we have a doc available to redirect to, that's our first option
  // otherwise we want to fallback to any provided redirectPath
  const DocRedirect = () => {
    if (doc.slug && redirectedPath?.includes('/docs')) {
      return <Redirect to={location.pathname === '/docs' ? `docs/${doc.slug}` : `${doc.slug}`} />;
    } else if (redirectedPath) {
      return <Redirect to={redirectedPath} />;
    }

    return null;
  };

  const hasMdxError = doc.mdx?.status === 'error';

  return (
    <main className="rm-Guides">
      {!!redirected && <DocRedirect />}
      <div className="rm-Container rm-Container_flex">
        <Sidebar pathRoot="docs" {...guideSidebar} />
        <article className={`rm-Article ${loading ? 'rm-Guides_loading' : ''}`} id="content">
          {!!doc && (
            <React.Fragment>
              {!!suggestedEditsPreview && !!isClient && (
                <SafeModeToggle onClick={() => setSafeMode(!safeMode)}>
                  {safeMode ? 'Show' : 'Hide'} HTML
                </SafeModeToggle>
              )}
              <Header
                excerpt={doc.excerpt}
                lang={props.lang}
                slugUrl={doc.slug}
                suggestedEdits={!!enableSuggestedEdits && !suggestedEditsPreview}
                title={doc.title}
              />
              {!loading && (
                <DocBody
                  dehydrated={dehydrated}
                  docBody={doc.body}
                  footer={<DocFooter doc={doc} project={project} version={version} />}
                  hasMdxError={hasMdxError}
                  hideTOC={project.appearance.hideTableOfContents}
                  mdx={project.flags.mdx}
                  opts={rdmdOpts}
                />
              )}
            </React.Fragment>
          )}
          <div className="ModalWrapper" id="tutorialmodal-root" />
        </article>
      </div>
    </main>
  );
};

Doc.propTypes = {
  activeDoc: PropTypes.string,
  config: PropTypes.object,
  customBlocks: PropTypes.object,
  doc: PropTypes.shape({
    excerpt: PropTypes.string,
    title: PropTypes.string,
  }),
  hideTOC: PropTypes.bool,
  lang: PropTypes.string,
  meta: PropTypes.object,
  project: PropTypes.object,
  rdmd: PropTypes.shape({
    dehydrated: PropTypes.object.isRequired,
    opts: PropTypes.object.isRequired,
  }).isRequired,
  sidebars: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    refs: PropTypes.array.isRequired,
  }).isRequired,
  suggestedEditsPreview: PropTypes.bool,
  user: PropTypes.object,
  version: PropTypes.object,
};

export default Doc;
