import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import Button from '@ui/Button';
import Tooltip from '@ui/Tooltip';

type SafeModeToggleProps = React.ComponentProps<typeof Button>;

const safeModeWarningText =
  'To prevent malicious activity, HTML is hidden by default in suggested edits. Only publish custom HTML from authors you trust.';

const SafeModeToggle = (props: SafeModeToggleProps) => {
  /* This toggle component injects itself in to the suggested
   * edits preview banner via a React portal. This is a workaround
   * until we refactor our banners (see also: our modals) to be
   * more "declartive".
   */
  const [el, setEl] = useState(document.querySelector('[data-testid="suggested-edits-preview"]'));
  useEffect(() => {
    /* Force-update the portals target element ref on rerender to
     * ensure that backwards + forward navigation events work.
     */
    setEl(document.querySelector('[data-testid="suggested-edits-preview"]'));
  }, []);
  if (!el) return null;
  return createPortal(
    <>
      &nbsp;
      <Tooltip content={safeModeWarningText} delay={[250, null]} offset={[0, 15]} touch={['hold', 500]}>
        <Button {...props} circular kind="contrast" outline size="xs" />
      </Tooltip>
    </>,
    el,
  );
};

export default SafeModeToggle;
